import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const MenuItem = props => {
  const { theme, item: { label, to, icon: Icon, subMenu } = {}, onClick } = props;

  return (
    <React.Fragment>
      <li className="item" key={label}>
        {subMenu ? (
          <React.Fragment>
            <span>
              {Icon && <Icon />} {label}
            </span>
            <ul>
              {subMenu.map((item, i) => (
                <MenuItem key={i} item={item} theme={theme} onClick={onClick} />
              ))}
            </ul>
          </React.Fragment>
        ) : (
          <Link
            to={to}
            className={"hiddenItem" in props ? "inHiddenItem" : ""}
            onClick={onClick}
            data-slug={to}
          >
            {Icon && <Icon />} {label}
          </Link>
        )}
      </li>

      {/* --- STYLES --- */}
      <style jsx>{`
        .item,
        .showItem {
          background: transparent;
          transition: all ${theme.time.duration.default};
          display: flex;
          align-items: left;
          flex-direction: column;
          just-content: flex-start;
          padding-left: ${theme.space.m};

          :global(a),
          :global(span) {
            padding: ${theme.space.inset.s};
            display: flex;
            align-items: center;
            font-size: ${theme.font.size.xs};
          }

          :global(svg) {
            margin: 0 ${theme.space.inset.xs} 0 0;
            opacity: 0.3;
          }
        }

        :global(.itemList .hideItem) {
          display: none;
        }

        @below desktop {
          .item {
            :global(a),
            :global(span) {
              color: ${theme.text.color.primary};
              padding: ${theme.space.inset.s};
              transition: all ${theme.time.duration.default};
              border-radius: ${theme.size.radius.small};
            }

            :global(.homepage):not(.fixed) & :global(a) {
              color: ${theme.text.color.primary};
            }

            :global(a:hover) {
              color: ${theme.color.brand.primary};
              background: color(black alpha(-90%));
            }

            :global(svg) {
              transition: all ${theme.time.duration.default};
            }

            global(a:hover) :global(svg) {
              fill: ${theme.color.brand.primary};
              opacity: 1;
            }
          }
        }
      `}</style>
    </React.Fragment>
  );
};

MenuItem.propTypes = {
  item: PropTypes.object,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.func,
  theme: PropTypes.object.isRequired
};

export default MenuItem;
