import React from "react";
import PropTypes from "prop-types";
import CheeseburgerMenu from "cheeseburger-menu";
import { FaHome, FaDollarSign, FaFootballBall, FaUsers, FaBasketballBall } from "react-icons/fa/";

require("core-js/fn/array/from");
// import { FaSearch } from "react-icons/fa/";
// import { FaEnvelope } from "react-icons/fa/";

import Item from "./Item";
import Login from "../Login";
import { isAuthenticated, login, logout } from "../../utils/auth";
import DropDownItem from "./DropDownItem";
import MenuItem from "./MenuItem";
class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.itemList = React.createRef();

    this.items = [
      { to: "/", label: "Home", icon: FaHome },
      {
        to: "",
        label: "NFL",
        icon: FaFootballBall,
        subMenu: [
          { to: "/blog/", label: "Articles" },
          // { to: "/nfl/season-long-rankings", label: "NFL Season Long Rankings" },
          { to: "/nfl/core-plays/", label: "Core Plays" },
          { to: "/nfl/ownership", label: "Ownership" },
          { to: "/nfl/optimizer", label: "Optimizer" }
          // { to: "/nfl/season-long-questions-and-answers", label: "Season Long Q&A" },
          // { to: "/nfl/sports-betting", label: "Sports Betting" },
          // { to: "/nfl/season-long-waiver-wire", label: "Season Long - Waiver Wire" }
        ]
      },
      {
        to: "/nba/core-plays",
        label: "NBA",
        icon: FaBasketballBall,
        subMenu: [{ to: "/nba/core-plays", label: "Core Plays" }]
      },
      { to: "/membership/", label: "Become a Member", icon: FaDollarSign },
      { to: "/about/", label: "About", icon: FaUsers }
    ];

    this.renderedItems = []; // will contain references to rendered DOM elements of menu
  }

  state = {
    open: false,
    authenticated: false,
    visible: false
  };

  static propTypes = {
    path: PropTypes.string.isRequired,
    fixed: PropTypes.bool.isRequired,
    screenWidth: PropTypes.number.isRequired,
    fontLoaded: PropTypes.bool.isRequired,
    pages: PropTypes.array.isRequired,
    theme: PropTypes.object.isRequired
  };

  login = () => {
    login();
    this.setState({
      authenticated: isAuthenticated()
    });
  };

  logout = () => {
    logout();
    this.setState({
      authenticated: isAuthenticated()
    });
  };

  componentDidMount() {
    // this.renderedItems = this.getRenderedItems();
    this.setState({
      authenticated: isAuthenticated()
    });
  }

  componentDidUpdate(prevProps) {}

  getRenderedItems = () => {
    const itemList = this.itemList.current;
    return Array.from(itemList.children);
  };

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  render() {
    const { theme } = this.props;
    const { open } = this.state;
    return (
      <React.Fragment>
        <nav className={`menu ${open ? "open" : ""}`} rel="js-menu">
          {this.props.screenWidth >= 1024 ? (
            <ul className="itemList" ref={this.itemList}>
              {this.items.map(item => {
                return item.subMenu ? (
                  <DropDownItem item={item} key={item.label} icon={item.icon} theme={theme} />
                ) : (
                  <Item item={item} key={item.label} icon={item.icon} theme={theme} />
                );
              })}
              <Login
                isAuthenticated={isAuthenticated()}
                login={this.login}
                logout={this.logout}
                theme={theme}
              />
            </ul>
          ) : (
            <React.Fragment>
              <button
                className="barsMenu"
                type="primary"
                onClick={this.showDrawer}
                aria-labelledby="Expand side nav"
              >
                <span className="barsBtn"></span>
              </button>
              <CheeseburgerMenu isOpen={this.state.visible} right closeCallback={this.onClose}>
                {this.items.map(item => (
                  <MenuItem item={item} key={item.label} theme={theme} onClick={this.onClose} />
                ))}
                {isAuthenticated() ? (
                  <MenuItem
                    item={{ to: "", label: "Logout" }}
                    theme={theme}
                    onClick={this.logout}
                  />
                ) : (
                  <MenuItem item={{ to: "", label: "Login" }} theme={theme} onClick={this.login} />
                )}
              </CheeseburgerMenu>
            </React.Fragment>
          )}
        </nav>

        {/* --- STYLES --- */}
        <style jsx>{`
          .menu {
            align-items: center;
            bottom: 0;
            display: flex;
            flex-grow: 1;
            left: 0;
            max-height: ${open ? "1000px" : "50px"};
            width: 100%;
            z-index: 1;
            border-top: none;
            background: transparent;
            position: relative;
            justify-content: flex-end;
            padding-left: 50px;
            transition: none;            
          }

          .itemList {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0; /* 0 ${theme.space.s}; */
            position: relative;
            
            justify-content: flex-end;
          }

          .barsMenu{
            float: right;
            padding: 0 6px;
            margin-top: 8px;
            padding: 0 6px;
            height: 32px;
            border-radius: 3px;
          }
    

          .barsBtn{
            display: block;
            width: 20px;
            height: 2px;
            background: #1890ff;
            position: relative;
          }

          .barsBtn:after,.barsBtn:before{
            content: attr(x);
            width: 20px;
            position: absolute;
            top: -6px;
            left: 0;
            height: 2px;
            background: #1890ff;
          }

          .barsBtn:after{
            top: auto;
            bottom: -6px;
          }

          @below desktop {
            :global(.cheeseburger-menu-inner) {
              padding-top: ${theme.space.m};
              padding-right: ${theme.space.m};
            }
            .menu {
              width: 20%;
              &::after {
                border-top: none;
                background: transparent;
                display: flex;
                position: relative;
                justify-content: flex-end;
                padding-left: 50px;
                transition: none;
              }

              &.open {
              }

              :global(.homepage):not(.fixed) & {
              }
            }
          }

          @from-width desktop {
            .menu {
              border-top: none;
              background: transparent;
              display: flex;
              position: relative;
              justify-content: flex-end;
              padding-left: 50px;
              transition: none;
            }

            .itemList {
              justify-content: flex-end;
              padding: 0;
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
}

export default Menu;
