/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// export const onClientEntry = () => {
//   // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//   if (typeof window.IntersectionObserver === `undefined`) {
//     import(`intersection-observer`);
//     console.log(`# IntersectionObserver is polyfilled!`);
//   }
// };
export const onClientEntry = async () => {
  // NOTE: Don't polyfill Promise here (Gatsby already includes a Promise polyfill)

  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
    console.log(`👍 IntersectionObserver is polyfilled`);
  }

  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement(`img`);
  if (
    typeof testImg.style.objectFit === `undefined` ||
    typeof testImg.style.objectPosition === `undefined`
  ) {
    await import(`object-fit-images`)();
    console.log(`👍 Object-fit/Object-position are polyfilled`);
  }

  // THIS IS FOR HEAP :)
  console.log(process.env.HEAP_ID);
  (window.heap = window.heap || []),
    (heap.load = function(e, t) {
      (window.heap.appid = e), (window.heap.config = t = t || {});
      var r = t.forceSSL || "https:" === document.location.protocol,
        a = document.createElement("script");
      // eslint-disable-next-line no-unused-expressions
      (a.type = "text/javascript"),
        (a.async = !0),
        (a.src = (r ? "https:" : "http:") + "//cdn.heapanalytics.com/js/heap-" + e + ".js");
      var n = document.getElementsByTagName("script")[0];
      n.parentNode.insertBefore(a, n);
      for (
        var o = function(e) {
            return function() {
              heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          },
          p = [
            "addEventProperties",
            "addUserProperties",
            "clearEventProperties",
            "identify",
            "resetIdentity",
            "removeEventProperty",
            "setEventProperties",
            "track",
            "unsetEventProperty"
          ],
          c = 0;
        c < p.length;
        c++
      )
        heap[p[c]] = o(p[c]);
    });
  heap.load(process.env.HEAP_ID);
};
