import React from "react";
import PropTypes from "prop-types";
import { Link, navigate } from "gatsby";

class DropDownItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }
  handleClick = to => {
    this.setState({ menuOpen: false });
  };

  handleIn = () => {
    if (!this.state.menuOpen) {
      this.setState({ menuOpen: true });
    }
  };

  handleOut = () => {
    if (this.state.menuOpen) {
      this.setState({ menuOpen: false });
    }
  };
  render() {
    const {
      theme,
      item: { label, to, icon: Icon, subMenu: subMenu, key: key } = {},
      onClick
    } = this.props;

    return (
      <React.Fragment>
        <li
          className="mainmenu item"
          key={key}
          onMouseEnter={this.handleIn}
          onMouseLeave={this.handleOut}
          // onMouseOut={this.handleOut}
        >
          <span to={to} className={"hiddenItem" in this.props ? "inHiddenItem" : ""}>
            {Icon && <Icon />} {label}
          </span>
          {this.state.menuOpen && (
            <ul className="menulist">
              {subMenu.map((subItem, index) => (
                <li key={index}>
                  <Link
                    className="subItem"
                    to={subItem.to}
                    onClick={() => this.handleClick(subItem.to)}
                  >
                    {subItem.icon && <Icon />} {subItem.label}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
        {/* --- STYLES --- */}
        <style jsx>{`
          .mainmenu {
            display: block;
            position: relative;

            > ul {
              margin: -2px;
              top: 40px;
              background: ${theme.color.neutral.white};
              box-shadow: ${theme.space.xs};
              list-style: none;
              visibility: visible;
              opacity: 0;
              padding: 0.5rem 0;
              min-width: 220px;
              position: absolute;
              padding: ${theme.space.sx};
              border-radius: ${theme.size.radius.small};
              border-top-right-radius: 0;
              box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              opacity: 1;

              > li {
                width: 100%;
              }
              > li > a {
                padding: ${theme.space.inset.xs};
                display: flex;
                align-items: center;
                color: ${theme.text.color.primary};
                font-size: ${theme.font.size.xs};

                :global(a:hover) {
                  color: ${theme.color.brand.primary};
                  background: color(white alpha(-60%));
                }
              }
            }
          }
          .item,
          .showItem {
            background: transparent;
            transition: all ${theme.time.duration.default};
            display: flex;
            align-items: center;
            display: block;

            :global(span) {
              padding: ${theme.space.inset.s};
              display: flex;
              align-items: center;
              font-size: ${theme.font.size.xs};
            }
            :global(a) {
              padding: ${theme.space.inset.s};
              display: flex;
              align-items: center;
              font-size: ${theme.font.size.xs};
            }

            :global(svg) {
              margin: 0 ${theme.space.inset.xs} 0 0;
              opacity: 0.3;
            }
          }

          :global(.itemList .hideItem) {
            display: none;
          }

          @from-width desktop {
            .mainmenu {
              :global(ul) {
                background: ${theme.color.neutral.white};
              }
              :global(.homepage):not(.fixed) & :global(ul) {
                background: color(white alpha(-60%));
              }
            }
            .item {
              :global(span),
              :global(a) {
                color: ${theme.text.color.primary};
                padding: ${theme.space.inset.s};
                transition: all ${theme.time.duration.default};
                border-radius: ${theme.size.radius.small};
              }
              :global(.homepage):not(.fixed) & :global(span) {
                color: ${theme.color.neutral.white};
              }

              :global(.homepage):not(.fixed) & :global(a) {
                color: ${theme.color.neutral.white};
              }

              :global(span:hover) {
                color: ${theme.color.brand.primary};
                background: color(white alpha(-60%));
                cursor: pointer;
              }
              :global(a:hover) {
                color: ${theme.color.brand.primary};
                background: color(white alpha(-60%));
              }

              :global(svg) {
                transition: all ${theme.time.duration.default};
              }

              &:hover :global(svg) {
                fill: ${theme.color.brand.primary};
                opacity: 1;

                :global(.hero) & :global(svg) {
                  fill: green;
                }
              }
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
}
DropDownItem.propTypes = {
  item: PropTypes.object,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.func,
  theme: PropTypes.object.isRequired
};

export default DropDownItem;
